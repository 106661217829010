import { Outlet, NavLink, Link } from "react-router-dom";

import openai from "../../assets/kpmg.svg";
// import { WarningBanner } from "../../components/WarningBanner/WarningBanner";
import styles from "./Layout.module.css";

interface Props {
    title?: string;
    themeColor?: string;
}

const Layout = ({ title, themeColor }: Props) => {
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"} style={{ background: `${themeColor}` || "#0E1F58" }}>
                {/* <WarningBanner /> */}
                <div className={styles.headerContainer}>
                    <div className={styles.headerTitleContainer}>
                        <img src={openai} alt="KPMG" className={styles.headerLogo} />
                        <h3 className={styles.headerTitle}>{title}</h3>
                    </div>
                    <nav>
                        <ul className={styles.headerNavList}>
                            {/* <li>
                                <NavLink to="/" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                    Chat
                                </NavLink>
                            </li> */}
                            <li className={styles.headerNavLeftMargin}>
                                {window.location.href.includes("/?isAdmin=true") && (
                                    <NavLink to="/content" className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}>
                                        Manage Content
                                    </NavLink>
                                )}
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
            {/* <div className={styles.raibanner}>
                <span className={styles.raiwarning}>AI-generated content may be incorrect</span>
            </div> */}

            <Outlet />

            {/* <footer>
                <WarningBanner />
            </footer> */}
        </div>
    );
};

export default Layout;
