//
//

import { useEffect, useState } from "react";
import { Pivot, PivotItem, Text } from "@fluentui/react";
import { Label } from "@fluentui/react/lib/Label";
import { Separator } from "@fluentui/react/lib/Separator";
import DOMPurify from "dompurify";
import ReactMarkdown from 'react-markdown';

import styles from "./AnalysisPanel.module.css";

import { SupportingContent } from "../SupportingContent";
import { AskResponse, ActiveCitation, getCitationObj, ChatResponse } from "../../api";
import { AnalysisPanelTabs } from "./AnalysisPanelTabs";
import useMobileScreen from "../../pages/chat/useMobileScreen";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import type { PDFDocumentProxy } from "pdfjs-dist";

pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.js", import.meta.url).toString();

const options = {
    cMapUrl: "/cmaps/",
    standardFontDataUrl: "/standard_fonts/"
};

const resizeObserverOptions = {};

const maxWidth = 800;

type PDFFile = string | File | null;

interface Props {
    className: string;
    activeTab: AnalysisPanelTabs;
    onActiveTabChanged: (tab: AnalysisPanelTabs) => void;
    activeCitation: string | undefined;
    sourceFile: string | undefined;
    pageNumber: string | undefined;
    citationHeight: string;
    answer: AskResponse;
}
interface V2Props {
    className: string;
    activeTab: AnalysisPanelTabs;
    onActiveTabChanged: (tab: AnalysisPanelTabs) => void;
    activeCitation: string | undefined;
    sourceFile: string | undefined;
    pageNumber: string | undefined;
    citationHeight: string;
    answer: ChatResponse;
}

const pivotItemDisabledStyle = { disabled: true, style: { color: "grey" } };

export const AnalysisPanel = ({ answer, activeTab, activeCitation, sourceFile, pageNumber, citationHeight, className, onActiveTabChanged }: Props) => {
    const [activeCitationObj, setActiveCitationObj] = useState<ActiveCitation>();

    const isDisabledThoughtProcessTab: boolean = !answer.thoughts;
    const isDisabledSupportingContentTab: boolean = !answer.data_points.length;
    const isDisabledCitationTab: boolean = !activeCitation;
    // the first split on ? separates the file from the sas token, then the second split on . separates the file extension
    const sourceFileExt: any = sourceFile?.split("?")[0].split(".").pop();
    const sanitizedThoughts = DOMPurify.sanitize(answer.thoughts!);
    const isMobile = useMobileScreen();

    const [numPages, setNumPages] = useState<number>();
    function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy): void {
        setNumPages(nextNumPages);
    }
    async function fetchActiveCitationObj() {
        try {
            const citationObj = await getCitationObj(activeCitation as string);
            setActiveCitationObj(citationObj);
            console.log(citationObj);
        } catch (error) {
            // Handle the error here
            console.log(error);
        }
    }

    function extractFileName(file_name: any) {
        if (!file_name) return "";
        let fileName = file_name.substring(file_name.lastIndexOf("/") + 1);
        fileName = fileName.replace(/-/g, " ");
        fileName = fileName.charAt(0).toUpperCase() + fileName.slice(1);
        return fileName;
    }

    useEffect(() => {
        fetchActiveCitationObj();
    }, [activeCitation]);

    return (
        <Pivot
            className={className}
            selectedKey={activeTab}
            onLinkClick={pivotItem => pivotItem && onActiveTabChanged(pivotItem.props.itemKey! as AnalysisPanelTabs)}
        >
            
            <PivotItem
                itemKey={AnalysisPanelTabs.ThoughtProcessTab}
                headerText="Thought process"
                headerButtonProps={isDisabledThoughtProcessTab ? pivotItemDisabledStyle : undefined}
            >
                <div className={styles.thoughtProcess} dangerouslySetInnerHTML={{ __html: sanitizedThoughts }}></div>
            </PivotItem>
            <PivotItem
                itemKey={AnalysisPanelTabs.SupportingContentTab}
                headerText="Supporting content"
                headerButtonProps={isDisabledSupportingContentTab ? pivotItemDisabledStyle : undefined}
            >
                <SupportingContent supportingContent={answer.data_points} />
            </PivotItem>
            <PivotItem
                itemKey={AnalysisPanelTabs.CitationTab}
                headerText="Citation"
                headerButtonProps={isDisabledCitationTab ? pivotItemDisabledStyle : undefined}
            >
                <Pivot className={styles.citationPivot}>
                    {!isMobile && (
                        <PivotItem itemKey="rawFile" headerText="Document">
                            {sourceFileExt === "pdf" ? (
                                //use object tag for pdfs because iframe does not support page numbers
                                <object data={sourceFile + "#page=" + pageNumber} type="application/pdf" width="100%" height={citationHeight} />
                            ) : sourceFileExt === "docx" ? (
                                <iframe
                                    title="Source File"
                                    src={
                                        "https://view.officeapps.live.com/op/view.aspx?src=" +
                                        encodeURIComponent(sourceFile as string) +
                                        "&action=embedview&wdStartOn=" +
                                        pageNumber
                                    }
                                    width="100%"
                                    height={citationHeight}
                                />
                            ) : (
                                <iframe title="Source File" src={sourceFile} width="100%" height={citationHeight} />
                            )}
                        </PivotItem>
                    )}
                    {isMobile && (
                        <PivotItem itemKey="rawFile" headerText="Document">
                            <Document file={sourceFile} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page key={`page_${index + 1}`} pageNumber={index + 1} width={300} />
                                ))}
                            </Document>
                        </PivotItem>
                    )}
                    <PivotItem itemKey="indexedFile" headerText="Document Section">
                        {activeCitationObj === undefined ? (
                            <Text>Loading...</Text>
                        ) : (
                            <div className={styles.citationContainer}>
                                <Separator>Metadata</Separator>
                                <Label>File Name</Label>
                                <Text>{extractFileName(activeCitationObj?.file_name)}</Text>
                                <Label>File URI</Label>
                                <Text>{activeCitationObj.file_uri}</Text>
                                <Label>Title</Label>
                                <Text>{activeCitationObj.title}</Text>
                                <Label>Section</Label>
                                <Text>{activeCitationObj.section}</Text>
                                <Label>Page Number(s)</Label>
                                <Text>{activeCitationObj.pages?.join(",")}</Text>
                                <Label>Token Count</Label>
                                <Text>{activeCitationObj.token_count}</Text>
                                <Separator>Content</Separator>
                                <Label>Content</Label>
                                <Text>{activeCitationObj.content}</Text>
                            </div>
                        )}
                    </PivotItem>
                </Pivot>
            </PivotItem>
        </Pivot>
    );
};



export const AnalysisPanelV2 = ({ answer, activeTab, activeCitation, sourceFile, pageNumber, citationHeight, className, onActiveTabChanged }: V2Props) => {
    const [activeCitationObj, setActiveCitationObj] = useState<ActiveCitation>();
    const [markdownContent, setMarkdownContent] = useState('');
    const [plainTextContent, setPlainTextContent] = useState('');

    const isDisabledThoughtProcessTab: boolean = !answer.thoughts;
    const isDisabledSupportingContentTab: boolean = !answer.data_points?.length;
    const isDisabledCitationTab: boolean = !activeCitation;
    // the first split on ? separates the file from the sas token, then the second split on . separates the file extension
    const sourceFileExt: any = sourceFile?.split("?")[0].split(".").pop();
    const sanitizedThoughts = DOMPurify.sanitize(answer.thoughts!);
    const isMobile = useMobileScreen();

    const [numPages, setNumPages] = useState<number>();
    function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy): void {
        setNumPages(nextNumPages);
    }
    async function fetchActiveCitationObj() {
        try {
            const citationObj = await getCitationObj(activeCitation as string);
            setActiveCitationObj(citationObj);
        } catch (error) {
            // Handle the error here
            console.log(error);
        }
    }

    function extractFileName(file_name: any) {
        if (!file_name) return "";
        let fileName = file_name.substring(file_name.lastIndexOf("/") + 1);
        fileName = fileName.replace(/-/g, " ");
        fileName = fileName.charAt(0).toUpperCase() + fileName.slice(1);
        return fileName;
    }

    useEffect(() => {
        fetchActiveCitationObj();
    }, [activeCitation]);
    useEffect(() => {
        if (!sourceFile) {
            return;
        }
        const fetchMarkdownContent = async () => {
            try {
                const response = await fetch(sourceFile!);
                const content = await response.text();
                setMarkdownContent(content);
            } catch (error) {
                console.error('Error fetching Markdown content:', error);
            }
        };

        fetchMarkdownContent();
    }, [sourceFile]);

    useEffect(() => {
        const fetchPlainTextContent = async () => {
            try {
                const response = await fetch(sourceFile!);
                const content = await response.text();
                setPlainTextContent(content);
            } catch (error) {
                console.error('Error fetching plain text content:', error);
            }
        };
    
        if (["json", "txt", "xml"].includes(sourceFileExt)) {
            fetchPlainTextContent();
        }
    }, [sourceFile, sourceFileExt]);

    return (
        <Pivot
            className={className}
            selectedKey={activeTab}
            onLinkClick={pivotItem => pivotItem && onActiveTabChanged(pivotItem.props.itemKey! as AnalysisPanelTabs)}
        >         
            <PivotItem
                itemKey={AnalysisPanelTabs.ThoughtProcessTab}
                headerText="Thought process"
                headerButtonProps={isDisabledThoughtProcessTab ? pivotItemDisabledStyle : undefined}
            >
                <div className={styles.thoughtProcess} dangerouslySetInnerHTML={{ __html: sanitizedThoughts }}></div>
            </PivotItem>
            <PivotItem
                itemKey={AnalysisPanelTabs.SupportingContentTab}
                headerText="Supporting content"
                headerButtonProps={isDisabledSupportingContentTab ? pivotItemDisabledStyle : undefined}
            >
                <SupportingContent supportingContent={answer.data_points} />
            </PivotItem>
            <PivotItem
                itemKey={AnalysisPanelTabs.CitationTab}
                headerText="Citation"
                headerButtonProps={isDisabledCitationTab ? pivotItemDisabledStyle : undefined}
            >
                <Pivot className={styles.citationPivot}>
                    {!isMobile && (
                        <PivotItem itemKey="rawFile" headerText="Document">
                            {sourceFileExt === "pdf" ? (
                                //use object tag for pdfs because iframe does not support page numbers
                                <object data={sourceFile + "#page=" + pageNumber} type="application/pdf" width="100%" height={citationHeight} />
                            ) : sourceFileExt === "docx" ? (
                                <iframe
                                    title="Source File"
                                    src={
                                        "https://view.officeapps.live.com/op/view.aspx?src=" +
                                        encodeURIComponent(sourceFile as string) +
                                        "&action=embedview&wdStartOn=" +
                                        pageNumber
                                    }
                                    width="100%"
                                    height={citationHeight}
                                />
                            ) : (
                                <iframe title="Source File" src={sourceFile} width="100%" height={citationHeight} />
                            )}
                        </PivotItem>
                    )}
                    {isMobile && (
                        <PivotItem itemKey="rawFile" headerText="Document">
                            <Document file={sourceFile} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page key={`page_${index + 1}`} pageNumber={index + 1} width={300} />
                                ))}
                            </Document>
                        </PivotItem>
                    )}
                    <PivotItem itemKey="indexedFile" headerText="Document Section">
                        {activeCitationObj === undefined ? (
                            <Text>Loading...</Text>
                        ) : (
                            <div className={styles.citationContainer}>
                                <Separator>Metadata</Separator>
                                <Label>File Name</Label>
                                <Text>{extractFileName(activeCitationObj?.file_name)}</Text>
                                <Label>File URI</Label>
                                <Text>{activeCitationObj.file_uri}</Text>
                                <Label>Title</Label>
                                <Text>{activeCitationObj.title}</Text>
                                <Label>Section</Label>
                                <Text>{activeCitationObj.section}</Text>
                                <Label>Page Number(s)</Label>
                                <Text>{activeCitationObj.pages?.join(",")}</Text>
                                <Label>Token Count</Label>
                                <Text>{activeCitationObj.token_count}</Text>
                                <Separator>Content</Separator>
                                <Label>Content</Label>
                                <Text>{activeCitationObj.content}</Text>
                            </div>
                        )}
                    </PivotItem>
                </Pivot>
            </PivotItem>
        </Pivot>
    );
};