import { useState } from "react";
import { Stack, TextField } from "@fluentui/react";
import { Send28Filled, AddSquare24Regular } from "@fluentui/react-icons";
import { RAIPanel } from "../RAIPanel";
import Tooltip from "@mui/material/Tooltip";
import styles from "./QuestionInput.module.css";
import { Button } from "react-bootstrap";
import CustomUpload from "../CustomUpload/CustomUpload";
import { bool } from "prop-types";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Attach from "../../assets/attach.png";

interface Props {
    onSend: (question: string, uploadedFiles: File[]) => void;
    onFileAdded: (uploadedFiles: File[]) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    onAdjustClick?: () => void;
    onInfoClick?: () => void;
    showClearChat?: boolean;
    onClearClick?: () => void;
    onRegenerateClick?: () => void;
    themeColor?: string;
    envConfig?: any;
    activeChatMode?: number;
}

export const QuestionInput = ({
    onSend,
    disabled,
    placeholder,
    clearOnSend,
    onAdjustClick,
    showClearChat,
    onClearClick,
    onRegenerateClick,
    themeColor,
    envConfig,
    activeChatMode,
    onFileAdded
}: Props) => {
    const [question, setQuestion] = useState<string>("");
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        onSend(question, uploadedFiles);

        if (clearOnSend) {
            setQuestion("");
            setUploadedFiles([]);
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (!newValue) {
            setQuestion("");
        } else if (newValue.length <= 1000) {
            setQuestion(newValue);
        }
    };

    const sendQuestionDisabled = disabled || !question.trim();

    const [clearChatTextEnabled, setClearChatTextEnable] = useState<boolean>(true);

    const onMouseEnter = () => {
        setClearChatTextEnable(false);
    };

    const onMouseLeave = () => {
        setClearChatTextEnable(true);
    };

    const [upload, setUpload] = useState<boolean>(false);

    const handleAttach = () => {
        setUpload(true);
    };

    const uploadOpen = () => {
        setUpload(true);
    };

    const handleUploadOpen = () => {
        setUpload(false);
    };

    const handleFileUpload = (files: File[]) => {
        setUploadedFiles(files);
        onFileAdded(files);
    };

    return (
        <Stack>
            <Stack.Item>
                <Stack horizontal className={styles.questionInputContainer}>
                    {activeChatMode === 1 && (
                        <Tooltip arrow title="Enables you to preload contextual information to guide the models response in a more focused and controlled manner.">
                            <div
                                className={styles.questionInputAttachFile}
                                onClick={handleAttach}
                                style={{ background: `${envConfig?.application_theme_color}` }}
                            >
                                <img src={Attach} width="25px" height="25px" alt="Attach file" />
                            </div>
                        </Tooltip>
                    )}
                    {upload && (
                        <Modal open={upload} onClose={handleUploadOpen}>
                            <Box
                                sx={{
                                    position: "absolute" as "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    width: "80%",
                                    // maxWidth: "1120px",
                                    bgcolor: "background.paper",
                                    boxShadow: 24,
                                    outline: 0,
                                    overflowY: "auto",
                                    // height: "80%",
                                    // maxHeight: "940px",
                                    borderRadius: 3.75,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    gap: 2,
                                    border: false ? "2px dashed #000" : "2px solid transparent"
                                }}
                            >
                                <CustomUpload envConfig={envConfig} themeColor={themeColor} onFileUpload={handleFileUpload} onClose={handleUploadOpen} />
                            </Box>
                        </Modal>
                    )}
                    <TextField
                        className={styles.questionInputTextArea}
                        placeholder={placeholder}
                        multiline
                        resizable={false}
                        borderless
                        value={question}
                        onChange={onQuestionChange}
                        onKeyDown={onEnterPress}
                    />
                    <div className={styles.questionInputButtonsContainer}>
                        <div
                            className={`${styles.questionInputSendButton} ${sendQuestionDisabled ? styles.questionInputSendButtonDisabled : ""}`}
                            aria-label="Ask question button"
                            onClick={sendQuestion}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 29 30" fill="none">
                                <path
                                    d="M26.1373 15C26.1372 14.7829 26.0793 14.5697 25.9695 14.3824C25.8597 14.1951 25.702 14.0405 25.5127 13.9343L12.6179 6.71326C12.401 6.59176 12.1521 6.53937 11.9046 6.5631C11.6571 6.58683 11.4227 6.68555 11.2327 6.84606C11.0428 7.00657 10.9064 7.22122 10.8418 7.46133C10.7771 7.70144 10.7873 7.95556 10.8709 8.18973L13.085 14.3893L18.8373 14.3893C18.9993 14.3893 19.1547 14.4536 19.2692 14.5681C19.3837 14.6827 19.4481 14.838 19.4481 15C19.4481 15.162 19.3837 15.3173 19.2692 15.4319C19.1547 15.5464 18.9993 15.6108 18.8373 15.6108L13.085 15.6108L10.8709 21.8103C10.7919 22.0273 10.7767 22.2624 10.827 22.4878C10.8774 22.7132 10.9912 22.9195 11.155 23.0824C11.1797 23.1071 11.2056 23.131 11.2328 23.154C11.422 23.3158 11.6565 23.4153 11.9044 23.4391C12.1522 23.4628 12.4014 23.4096 12.6179 23.2867L25.5127 16.0657C25.702 15.9595 25.8597 15.8048 25.9695 15.6175C26.0792 15.4302 26.1372 15.2171 26.1373 15Z"
                                    fill="#798296"
                                />
                            </svg>
                        </div>
                    </div>
                </Stack>
            </Stack.Item>
            {/* <Stack.Item align="center">
                <RAIPanel onAdjustClick={onAdjustClick} onRegenerateClick={onRegenerateClick} />
            </Stack.Item> */}
        </Stack>
    );
};
