import React, { useState, useRef, ChangeEvent, DragEvent, MouseEvent } from "react";
import "../../index.css";

interface FileWithPreview extends File {
    preview?: string;
}

interface Props {
    envConfig?: any;
    themeColor?: string;
    onFileUpload: (files: FileWithPreview[]) => void;
    onClose: () => void;
}

const CustomUpload: React.FC<Props> = ({ envConfig, themeColor, onFileUpload, onClose }) => {
    const [selectedFiles, setSelectedFiles] = useState<FileWithPreview[]>([]);
    const [error, setError] = useState<string>("");

    const fileInputRef = useRef<HTMLInputElement>(null);
    // const acceptedFileExtensions = ["txt", "pdf", "doc", "docx", "ppt", "pptx"];
    const acceptedFileExtensions = ["pdf", "docx", "csv"];
    const acceptedFileTypesString = acceptedFileExtensions.map(ext => `.${ext}`).join(",");

    const handleSubmit = (): void => {
        console.log(selectedFiles.length);
        if (selectedFiles.length === 0) {
            setError("File is required");
        } else if (selectedFiles.length > 1) {
            setError("Only one file can be uploaded");
        } else if (selectedFiles.length == 1) {
            onFileUpload(selectedFiles);
            onClose();
            setSelectedFiles([]);
            setError("");
        }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const newFilesArray = Array.from(event.target.files || []);
        processFiles(newFilesArray);
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files);
        processFiles(droppedFiles);
    };

    const processFiles = (filesArray: FileWithPreview[]): void => {
        const newSelectedFiles = [...selectedFiles];
        let hasError = false;
        const fileTypeRegex = new RegExp(acceptedFileExtensions.join("|"), "i");

        filesArray.forEach(file => {
            if (newSelectedFiles.some(f => f.name === file.name)) {
                setError("File names must be unique");
                hasError = true;
            } else if (!fileTypeRegex.test(file.name.split(".").pop() || "")) {
                setError(`Only ${acceptedFileExtensions.join(", ")} files are allowed`);
                hasError = true;
            } else {
                newSelectedFiles.push(file);
            }
        });

        if (!hasError) {
            setError("");
            setSelectedFiles(newSelectedFiles);
        }
    };

    const handleCustomButtonClick = (): void => {
        fileInputRef.current?.click();
    };

    const handleFileDelete = (index: number): void => {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);
    };
    return (
        <div className="flex justify-center items-center w-full ">
            <div className="w-full p-8 bg-white rounded-lg ">
                <h2 className="text-2xl font-semibold text-center mb-4">Upload Files</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div
                        className="min-h-[23rem] border-4 border-dashed border-blue-500 bg-blue-100 rounded-3xl p-4 flex flex-col justify-center items-center space-y-4"
                        onDragOver={e => e.preventDefault()}
                        onDrop={e => handleDrop(e)}
                        style={{ border: `4px dashed ${envConfig?.application_theme_color}`, background: `${envConfig?.application_theme_color}1A` }}
                    >
                        <div className="w-24 h-24 mb-2">
                            <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M87.4974 29.1663H74.9974C72.7873 29.1663 70.6676 28.2884 69.1048 26.7256C67.542 25.1628 66.6641 23.0431 66.6641 20.833V8.33301"
                                    stroke={envConfig?.application_theme_color}
                                    stroke-width="4"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M87.5026 24.9997V52.083C87.5026 55.4163 84.5859 58.333 81.2526 58.333H52.0859C48.7526 58.333 45.8359 55.4163 45.8359 52.083V14.583C45.8359 11.2497 48.7526 8.33301 52.0859 8.33301H70.8359L87.5026 24.9997Z"
                                    stroke={envConfig?.application_theme_color}
                                    stroke-width="4"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M29.1641 33.333V69.9997C29.1641 71.2497 29.9974 72.4997 30.8307 73.333C31.6641 74.1663 32.9141 74.9997 34.1641 74.9997H62.4974"
                                    stroke={envConfig?.application_theme_color}
                                    stroke-width="4"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M12.5 50V86.6667C12.5 87.9167 13.3333 89.1667 14.1667 90C15 90.8333 16.25 91.6667 17.5 91.6667H45.8333"
                                    stroke={envConfig?.application_theme_color}
                                    stroke-width="4"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                        <p className="text-md font-semibold" style={{ textAlign: "center" }}>
                            Max file size is 10MB. File upload supports common text-based file types such as pdf, csv, doc{" "}
                        </p>
                        {/* <p className="text-lg font-semibold">Drag and Drop the files</p>
                        <p className="text-lg font-bold">or</p> */}
                        <button
                            type="button"
                            onClick={handleCustomButtonClick}
                            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                            style={{ background: `${envConfig?.application_theme_color}` }}
                        >
                            Upload Files
                        </button>
                        <input
                            type="file"
                            id="files"
                            name="files"
                            accept={acceptedFileTypesString}
                            ref={fileInputRef}
                            className="hidden"
                            onChange={handleFileChange}
                            onClick={(event: MouseEvent<HTMLInputElement>) => {
                                event.currentTarget.value = "";
                            }}
                        />
                    </div>

                    <div className="border-2 border-gray-300 rounded-3xl py-4 max-h-[23rem] overflow-auto">
                        {selectedFiles.length > 0 ? (
                            <ul className="px-4">
                                {selectedFiles.map((file, index) => (
                                    <li key={file.name} className="flex justify-between items-center border-b py-2">
                                        <div className="flex items-center  gap-1.5">
                                            <div className="w-8 mr-2">
                                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="40" height="40" rx="5" style={{ fill: `${themeColor}` || "black" }} />
                                                    <path
                                                        d="M29.677 24.9596L26.547 17.6496C25.487 15.1696 23.537 15.0696 22.227 17.4296L20.337 20.8396C19.377 22.5696 17.587 22.7196 16.347 21.1696L16.127 20.8896C14.837 19.2696 13.017 19.4696 12.087 21.3196L10.367 24.7696C9.157 27.1696 10.907 29.9996 13.587 29.9996H26.347C28.947 29.9996 30.697 27.3496 29.677 24.9596Z"
                                                        stroke="white"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        d="M14.9687 16C16.6256 16 17.9688 14.6569 17.9688 13C17.9688 11.3431 16.6256 10 14.9687 10C13.3119 10 11.9688 11.3431 11.9688 13C11.9688 14.6569 13.3119 16 14.9687 16Z"
                                                        stroke="white"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                            </div>

                                            <span className="text-base">{file.name}</span>
                                        </div>
                                        <div className="flex">
                                            <span className="text-sm text-gray-500 ml-2 whitespace-nowrap">({(file.size / 1024).toFixed(2)} KB)</span>
                                            <button
                                                type="button"
                                                onClick={() => handleFileDelete(index)}
                                                className="text-red-500 hover:text-red-700 focus:outline-none"
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" className="w-6 h-6">
                                                    <path stroke="currentColor" stroke-width="2" d="M6 4l8 8M14 4l-8 8" />
                                                </svg>
                                            </button>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <div className="h-full flex justify-center items-center">
                                <p className="text-lg font-semibold text-gray-500 text-center">No Files Uploaded Yet</p>
                            </div>
                        )}
                    </div>
                </div>
                {error && <p className="text-red-500 mt-4 text-center">{error}</p>}
                <div className="flex justify-center mt-8">
                    <button
                        type="button"
                        onClick={handleSubmit}
                        className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                        style={{ background: `${envConfig?.application_theme_color}` }}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CustomUpload;
