import { useState, useEffect } from "react";
import styles from "./Example.module.css";

interface Props {
    text: string;
    value: string;
    onClick: (value: string) => void;
    envConfig?: any;
}

export const Example = ({ text, value, onClick, envConfig }: Props) => {
    const wordLimit = 20;
    const [showMore, setShowMore] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 480);
    const [isHovered, setIsHovered] = useState(false);

    const updateMedia = () => {
        setIsMobile(window.innerWidth < 480);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    }, []);

    const textWords = text.split(" ");
    const shouldTruncate = isMobile && textWords.length > wordLimit;
    const textToShow = shouldTruncate && !showMore ? textWords.slice(0, wordLimit).join(" ") + "..." : text;

    const toggleShowMore = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        event.stopPropagation();
        setShowMore(!showMore);
    };

    const dynamicStyles = {
        boxShadow: isHovered ? `0px 8px 16px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)` : "none",
        outline: isHovered ? `2px solid ${envConfig?.application_theme_color}` : "none"
    };

    return (
        <div
            className={styles.example}
            onClick={() => onClick(value)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={dynamicStyles}
        >
            <p className={styles.exampleText}>
                {textToShow}
                {shouldTruncate && (
                    <span className={styles.showMoreText} onClick={toggleShowMore}>
                        {showMore ? " Show Less" : " Show More"}
                    </span>
                )}
            </p>
        </div>
    );
};
