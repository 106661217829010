//  
//  
import { useState, useEffect } from 'react';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { ComboBox,
    IComboBox,
    IComboBoxOption,
    IComboBoxStyles,
    SelectableOptionMenuItemType,
    TooltipHost,
    ITooltipHostStyles,
    ActionButton, 
    DirectionalHint} from "@fluentui/react";
import { TeachingBubble, ITeachingBubbleStyles } from '@fluentui/react/lib/TeachingBubble';
import { Info16Regular } from '@fluentui/react-icons';
import { ITextFieldStyleProps, ITextFieldStyles, TextField } from '@fluentui/react/lib/TextField';
import { ILabelStyles, ILabelStyleProps } from '@fluentui/react/lib/Label';
import { IIconProps } from '@fluentui/react';
import { IButtonProps } from '@fluentui/react/lib/Button';
import { BlobServiceClient } from "@azure/storage-blob";
import { getAllUploadedFiles } from "../../api";
import styles from "./FileDropdown.module.css";

var allowNewFolders = false;
interface Props {
    allowFolderCreation?: boolean;
    onFileSelectChange: (selectedFiles: any) => void;
    preSelectedKeys?: string[];
    selectedFolders: string[];
}
export const FileDropdown = ({allowFolderCreation, onFileSelectChange, preSelectedKeys,selectedFolders}: Props) => {
    const buttonId = useId('targetButton');
    const tooltipId = useId('folderpicker-tooltip');
    const textFieldId = useId('textField');
    const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(false);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [options, setOptions] = useState<IComboBoxOption[]>([]);
    const selectableOptions = selectedFolders ?  options?.filter(
        option =>
          (option?.itemType === SelectableOptionMenuItemType.Normal || option.itemType === undefined) && !option.disabled,
      ): [];
    const comboBoxStyles: Partial<IComboBoxStyles> = { root: { maxWidth: 300 } };
    const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };
    const addFolderIcon: IIconProps = { iconName: 'Add' };
    allowNewFolders = allowFolderCreation as boolean;
 
    
   
    async function fetchBlobFileData() {
        try {
            const files = await getAllUploadedFiles(selectedFolders);
          // setOptions([]);
            setSelectedKeys([])
           if(selectedFolders.length > 0) {
            if(files.response.length > 0) {
           
                var newOptions: IComboBoxOption[] = allowNewFolders ? [] : [
                    { key: 'selectAll', text: 'Select All', itemType: SelectableOptionMenuItemType.SelectAll },
                    { key: 'FileHeader', text: 'Files', itemType: SelectableOptionMenuItemType.Header }
                ];
                {
                    // Check if the item is a folder
                   const filesArray =  files.response.map(function (i: { [x: number]: any[]; }) {
                    let key:any =  Object.keys(i)
                return i[key].map(function (j: string) {
    
                    return  `${key[0]+"/"+j}`
                })
                    }).flat();
                    filesArray.map(function(i: string){
                        newOptions.push({key: i, text: i.split("/")[1]});
                    })
                   
                      setOptions(newOptions);
                      onFileSelectChange(newOptions);   
                  }
                  if (!allowNewFolders) {
                    var filteredOptions = newOptions.filter(
                        option =>
                          (option.itemType === SelectableOptionMenuItemType.Normal || option.itemType === undefined) && !option.disabled,
                      );
                    if (preSelectedKeys !== undefined && preSelectedKeys.length > 0) {
                        setSelectedKeys(preSelectedKeys);
                        onFileSelectChange(preSelectedKeys);
                    }
                    else {
                        setSelectedKeys(['selectAll', ...filteredOptions.map(o => o.key as string)]);
                        onFileSelectChange(['selectAll', ...filteredOptions.map(o => o.key as string)]);
                    }
                  } 
           } else  setOptions([]);
          
           } else {
            setOptions([]);
           }
           
        } catch (error) {
            // Handle the error here
            console.log(error);
        }
    }
    useEffect(() => {
        
            fetchBlobFileData();
        
       
    }, [selectedFolders]);

    const onChange = (
        event: React.FormEvent<IComboBox>,
        option?: IComboBoxOption,
        index?: number,
        value?: string,
      ): void => {
        const selected = option?.selected;
        const currentSelectedOptionKeys = selectedKeys.filter(key => key !== 'selectAll');
        const selectAllState = currentSelectedOptionKeys.length === selectableOptions.length;
        if (!allowNewFolders) {
            if (option) {
            if (option?.itemType === SelectableOptionMenuItemType.SelectAll) {
                if (selectAllState) {
                    setSelectedKeys([])
                    onFileSelectChange([]);
                }
                else {
                    setSelectedKeys(['selectAll', ...selectableOptions.map(o => o.key as string)]);
                    onFileSelectChange(['selectAll', ...selectableOptions.map(o => o.key as string)]);
                }
            } else {
                const updatedKeys = selected
                ? [...currentSelectedOptionKeys, option!.key as string]
                : currentSelectedOptionKeys.filter(k => k !== option.key);
                if (updatedKeys.length === selectableOptions.length) {
                updatedKeys.push('selectAll');
                }
                setSelectedKeys(updatedKeys);
                onFileSelectChange(updatedKeys);
            }
           
            }
        }
      };
    return (
        <div className={styles.folderArea}>
            <div className={styles.folderSelection}>
                <ComboBox
                    multiSelect={allowNewFolders? false : true}
                    label={"File selection:"}
                    options={options}
                    onChange={onChange}
                    styles={comboBoxStyles}
                    selectedKey={selectedKeys}
                />
                <TooltipHost content={"Select a file to filter the search by"}
                        styles={hostStyles}
                        id={tooltipId}>
                    <Info16Regular></Info16Regular>
                </TooltipHost>
            </div>
        </div>
    );
};
