import React, { useState, useEffect } from "react";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import styles from "./Prompts.module.css";
import { resetPrompt } from "../../api";
import { PromptKey } from "../../api/models";

const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75"
};

const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025"
};

const TextareaAutosize = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 1000px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    margin: 0;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
    }
  
    &:focus-visible {
      outline: 0;
    }
  `
);

const getPromptType = (promptKey: PromptKey): string => {
    switch (promptKey) {
        case "systemPrompt":
            return "system_prompt_prefix";
        case "followupPrompt":
            return "followup_question_prompt";
        case "queryPromptFewShots":
            return "query_prompt_few_shots";
        case "queryPromptTemplate":
            return "query_prompt_template";
        case "responsePromptFewShots":
            return "response_prompt_few_shots";
        default:
            throw new Error("Invalid PromptKey");
    }
};

interface Props {
    className?: string;
    promptsData: any;
    onUpdate: (promptType: string, prompt: string) => void;
}

const Prompts = ({ className, promptsData, onUpdate }: Props) => {
    const [localPrompts, setLocalPrompts] = useState<{
        [key in PromptKey]: string;
    }>({
        systemPrompt: "",
        followupPrompt: "",
        queryPromptFewShots: "",
        queryPromptTemplate: "",
        responsePromptFewShots: ""
    });

    useEffect(() => {
        if (promptsData) {
            setLocalPrompts({
                systemPrompt: promptsData.systemPrompt || "",
                followupPrompt: promptsData.followupPrompt || "",
                queryPromptFewShots: promptsData.queryPromptFewShots || "",
                queryPromptTemplate: promptsData.queryPromptTemplate || "",
                responsePromptFewShots: promptsData.responsePromptFewShots || ""
            });
        }
    }, [promptsData]);

    const handleUpdateClick = (promptType: PromptKey) => {
        onUpdate(getPromptType(promptType), localPrompts[promptType]);
    };

    const handleResetClick = async (promptType: PromptKey) => {
        try {
            const promptTypeKey = getPromptType(promptType);
            const response = await resetPrompt(promptTypeKey);
            const updatedPrompt = response.message[promptTypeKey] || "";
            setLocalPrompts(prevState => ({
                ...prevState,
                [promptType]: updatedPrompt
            }));
            alert("Prompt reset successfully!");
        } catch (error) {
            console.error("Error resetting prompt:", error);
            alert("Failed to reset prompt.");
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.promptContainer}>
                <Typography variant="body1" sx={{ fontSize: "17px" }}>
                    System Prompt Prefix:
                </Typography>
                <TextareaAutosize
                    aria-label="empty textarea"
                    minRows={4}
                    value={localPrompts.systemPrompt}
                    onChange={e => setLocalPrompts({ ...localPrompts, systemPrompt: e.target.value })}
                />
                <div className={styles.buttonContainer}>
                    <Button variant="text" sx={{ textTransform: "none" }} onClick={() => handleResetClick("systemPrompt")}>
                        Reset
                    </Button>
                    <div className={styles.rightButtons}>
                        {/* <Button variant="outlined" sx={{ textTransform: "none" }}>
                            Cancel
                        </Button> */}
                        <Button variant="contained" sx={{ textTransform: "none" }} onClick={() => handleUpdateClick("systemPrompt")}>
                            Update
                        </Button>
                    </div>
                </div>
            </div>
            <div className={styles.promptContainer}>
                <Typography variant="body1" sx={{ fontSize: "17px" }}>
                    Followup Question Prompt:
                </Typography>
                <TextareaAutosize
                    aria-label="empty textarea"
                    minRows={4}
                    value={localPrompts.followupPrompt}
                    onChange={e => setLocalPrompts({ ...localPrompts, followupPrompt: e.target.value })}
                />
                <div className={styles.buttonContainer}>
                    <Button variant="text" sx={{ textTransform: "none" }} onClick={() => handleResetClick("followupPrompt")}>
                        Reset
                    </Button>
                    <div className={styles.rightButtons}>
                        {/* <Button variant="outlined" sx={{ textTransform: "none" }}>
                            Cancel
                        </Button> */}
                        <Button variant="contained" sx={{ textTransform: "none" }} onClick={() => handleUpdateClick("followupPrompt")}>
                            Update
                        </Button>
                    </div>
                </div>
            </div>
            <div className={styles.promptContainer}>
                <Typography variant="body1" sx={{ fontSize: "17px" }}>
                    Query Prompt Few Shots:
                </Typography>
                <TextareaAutosize
                    aria-label="empty textarea"
                    minRows={4}
                    value={localPrompts.queryPromptFewShots}
                    onChange={e => setLocalPrompts({ ...localPrompts, queryPromptFewShots: e.target.value })}
                />
                <div className={styles.buttonContainer}>
                    <Button variant="text" sx={{ textTransform: "none" }} onClick={() => handleResetClick("queryPromptFewShots")}>
                        Reset
                    </Button>
                    <div className={styles.rightButtons}>
                        {/* <Button variant="outlined" sx={{ textTransform: "none" }}>
                            Cancel
                        </Button> */}
                        <Button variant="contained" sx={{ textTransform: "none" }} onClick={() => handleUpdateClick("queryPromptFewShots")}>
                            Update
                        </Button>
                    </div>
                </div>
            </div>
            <div className={styles.promptContainer}>
                <Typography variant="body1" sx={{ fontSize: "17px" }}>
                    Query Prompt Template:
                </Typography>
                <TextareaAutosize
                    aria-label="empty textarea"
                    minRows={4}
                    value={localPrompts.queryPromptTemplate}
                    onChange={e => setLocalPrompts({ ...localPrompts, queryPromptTemplate: e.target.value })}
                />
                <div className={styles.buttonContainer}>
                    <Button variant="text" sx={{ textTransform: "none" }} onClick={() => handleResetClick("queryPromptTemplate")}>
                        Reset
                    </Button>
                    <div className={styles.rightButtons}>
                        {/* <Button variant="outlined" sx={{ textTransform: "none" }}>
                            Cancel
                        </Button> */}
                        <Button variant="contained" sx={{ textTransform: "none" }} onClick={() => handleUpdateClick("queryPromptTemplate")}>
                            Update
                        </Button>
                    </div>
                </div>
            </div>
            <div className={styles.promptContainer}>
                <Typography variant="body1" sx={{ fontSize: "17px" }}>
                    Response Prompt Few Shots:
                </Typography>
                <TextareaAutosize
                    aria-label="empty textarea"
                    minRows={4}
                    value={localPrompts.responsePromptFewShots}
                    onChange={e => setLocalPrompts({ ...localPrompts, responsePromptFewShots: e.target.value })}
                />
                <div className={styles.buttonContainer}>
                    <Button variant="text" sx={{ textTransform: "none" }} onClick={() => handleResetClick("responsePromptFewShots")}>
                        Reset
                    </Button>
                    <div className={styles.rightButtons}>
                        {/* <Button variant="outlined" sx={{ textTransform: "none" }}>
                            Cancel
                        </Button> */}
                        <Button variant="contained" sx={{ textTransform: "none" }} onClick={() => handleUpdateClick("responsePromptFewShots")}>
                            Update
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Prompts;
