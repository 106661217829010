import {
    AskRequest,
    AskResponse,
    ChatRequest,
    BlobClientUrlResponse,
    AllFilesUploadStatus,
    GetUploadStatusRequest,
    GetInfoResponse,
    ActiveCitation,
    GetWarningBanner,
    StatusLogEntry,
    StatusLogResponse,
    ApplicationTitle,
    GetTagsResponse,
    GetFileRequest,
    GetFileResponse,
    chatHistory,
    ChatDeleteRequest,
    ChatRequestV2,
    PromptDataResponse,
    UpdatePromptRequest
} from "./models";

const base_url = "";
//const base_url = "http://127.0.0.1:8000";

export async function askApi(options: AskRequest): Promise<AskResponse> {
    const response = await fetch(`${base_url}/ask`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            question: options.question,
            approach: options.approach,
            overrides: {
                useSemanticRanker: options.overrides?.useSemanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                user_persona: options.overrides?.userPersona,
                system_persona: options.overrides?.systemPersona,
                ai_persona: options.overrides?.aiPersona
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function chatApi(options: ChatRequest): Promise<AskResponse> {
    const response = await fetch(`${base_url}/chat`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        signal: AbortSignal.timeout(720000),
        body: JSON.stringify({
            history: options.history,
            approach: options.approach,
            conversation_id: options.conversationId,
            overrides: {
                use_semantic_reranking: options.overrides?.useSemanticRanker,
                use_enriched_embeddings: options.overrides?.useEnrichedEmbeddings,
                use_multi_vector_query: options.overrides?.useMultiVectorQuery,
                query_transformation_type: options.overrides?.queryTransformationKey,
                retriver_feature: options.overrides?.retriverFeature,
                conversation_id: options.conversationId,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                rag_evaluation: options.overrides?.enableCompositeScore,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
                user_persona: options.overrides?.userPersona,
                system_persona: options.overrides?.systemPersona,
                ai_persona: options.overrides?.aiPersona,
                response_length: options.overrides?.responseLength,
                response_temp: options.overrides?.responseTemp,
                selected_folders: options.overrides?.selectedFolders,
                selected_tags: options.overrides?.selectedTags,
                selected_files: options.overrides?.selectedFiles,
                model_name: options.overrides?.modelName
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function chatApiV2(options: ChatRequestV2, signal: AbortSignal): Promise<Response> {
    const response = await fetch(`${base_url}/chat`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            history: options.history,
            approach: options.approach,
            user_id: options.userId,
            conversation_id: options.conversationId,
            overrides: {
                //semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
                byPassRAG: options.overrides?.byPassRAG,
                user_persona: options.overrides?.userPersona,
                system_persona: options.overrides?.systemPersona,
                ai_persona: options.overrides?.aiPersona,
                response_length: options.overrides?.responseLength,
                response_temp: options.overrides?.responseTemp,
                selected_folders: options.overrides?.selectedFolders,
                selected_tags: options.overrides?.selectedTags,
                query_transformation_type: options.overrides?.queryTransformationKey ? options.overrides?.queryTransformationKey : "normalized",
                conversation_id: options.conversationId,
                rag_evaluation: options.overrides?.enableCompositeScore ? options.overrides?.enableCompositeScore : false,
                retriver_feature: options.overrides?.retriverFeature ? options.overrides?.retriverFeature : "default",
                selected_files: options.overrides?.selectedFiles ? options.overrides?.selectedFiles : "All",
                use_semantic_reranking: options.overrides?.useSemanticRanker ? options.overrides?.useSemanticRanker : false,
                use_enriched_embeddings: options.overrides?.useEnrichedEmbeddings ? options.overrides?.useEnrichedEmbeddings : false,
                use_multi_vector_query: options.overrides?.useMultiVectorQuery ? options.overrides?.useMultiVectorQuery : false,
                model_name: options.overrides?.modelName ? options.overrides?.modelName : "azure/gpt-35-turbo-16k",
                system_prompt: options.overrides?.systemPrompt
            },
            citation_lookup: options?.citation_lookup,
            thought_chain: options?.thought_chain,
            chat_type: options.chat_type
        }),
        signal: signal
    });

    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }

    return response;
}

export async function groundedChatApi(options: ChatRequestV2, files: any): Promise<Response> {
    const myHeaders = new Headers();
    //myHeaders.append("Accept", "application/json");

    const formdata = new FormData();

    formdata.append("history", JSON.stringify(options.history));
    formdata.append("approach", options.approach);
    formdata.append("chat_type", options.chat_type);

    if (options.conversationId) {
        formdata.append("conversation_id", options.conversationId);
    }

    formdata.append(
        "overrides",
        JSON.stringify({
            response_length: options.overrides?.responseLength,
            response_temp: options.overrides?.responseTemp,
            model_name: options.overrides?.modelName,
            system_prompt:
                "You are a soft-spoken assistant, communicating in a gentle and calm manner, effortlessly providing the necessary help and support while maintaining a soothing tone. lYour voice conveys patience and understanding, making you approachable for those who require assistance."
        })
    );

    if (files) {
        formdata.append(`upload_file`, files, files.name);
    }

    const requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow"
    };

    const response = await fetch(`${base_url}/personal-rag-chat`, requestOptions);

    if (response.status > 299 || !response.ok) {
        throw Error("Unknown error");
    }

    console.log("GROUNDED RESPONSE", response);
    console.log("GROUNDED BODY", response.body);
    return response;
}

export function getCitationFilePath(citation: string): string {
    return `${encodeURIComponent(citation)}`;
}

export async function getBlobClientUrl(): Promise<string> {
    const response = await fetch(`${base_url}/getblobclienturl`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const parsedResponse: BlobClientUrlResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse.url;
}

export async function getAllUploadFiles(options: GetUploadStatusRequest): Promise<AllFilesUploadStatus> {
    const response = await fetch(`${base_url}/getalluploadstatusv2`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            timeframe: options.timeframe,
            state: options.state as string,
            folder: options.folder as string,
            tag: options.tag as string
        })
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }
    const results: AllFilesUploadStatus = { statuses: parsedResponse };
    return results;
}

export async function getAllUploadStatus(options: GetUploadStatusRequest): Promise<AllFilesUploadStatus> {
    const response = await fetch(`${base_url}/getalluploadstatusv2`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            timeframe: options.timeframe,
            state: options.state as string,
            folder: options.folder as string,
            tag: options.tag as string
        })
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }
    const results: AllFilesUploadStatus = { statuses: parsedResponse };
    return results;
}

export async function deleteItem(options: any): Promise<boolean> {
    try {
        const response = await fetch(`${base_url}/deleteItems`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                path: options.path
            })
        });
        if (!response.ok) {
            // If the response is not ok, throw an error
            const errorResponse = await response.json();
            throw new Error(errorResponse.error || "Unknown error");
        }
        //         // If the response is ok, return true
        return true;
    } catch (error) {
        console.error("Error during deleteItem:", error);
        return false;
    }
}

export async function resubmitItem(options: any): Promise<boolean> {
    try {
        const response = await fetch(`${base_url}/resubmitItems`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                path: options.path
            })
        });
        if (!response.ok) {
            // If the response is not ok, throw an error
            const errorResponse = await response.json();
            throw new Error(errorResponse.error || "Unknown error");
        }
        // If the response is ok, return true
        return true;
    } catch (error) {
        console.error("Error during deleteItem:", error);
        return false;
    }
}

export async function getFolders(): Promise<string[]> {
    const response = await fetch(`${base_url}/getfolders`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({})
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }
    // Assuming parsedResponse is the array of strings (folder names) we want
    // Check if it's actually an array and contains strings
    if (Array.isArray(parsedResponse) && parsedResponse.every(item => typeof item === "string")) {
        return parsedResponse;
    } else {
        throw new Error("Invalid response format");
    }
}

export async function getTags(): Promise<string[]> {
    const response = await fetch(`${base_url}/gettags`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({})
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }
    // Assuming parsedResponse is the array of strings (folder names) we want
    // Check if it's actually an array and contains strings
    if (Array.isArray(parsedResponse) && parsedResponse.every(item => typeof item === "string")) {
        return parsedResponse;
    } else {
        throw new Error("Invalid response format");
    }
}

export async function logStatus(status_log_entry: StatusLogEntry): Promise<StatusLogResponse> {
    var response = await fetch(`${base_url}/logstatus`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            path: status_log_entry.path,
            status: status_log_entry.status,
            status_classification: status_log_entry.status_classification,
            state: status_log_entry.state
        })
    });

    var parsedResponse: StatusLogResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    var results: StatusLogResponse = { status: parsedResponse.status };
    return results;
}

export async function getInfoData(): Promise<GetInfoResponse> {
    const response = await fetch(`${base_url}/getInfoData`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
    const parsedResponse: GetInfoResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        console.log(response);
        throw Error(parsedResponse.error || "Unknown error");
    }
    console.log(parsedResponse);
    return parsedResponse;
}

export async function getWarningBanner(): Promise<GetWarningBanner> {
    const response = await fetch(`${base_url}/getWarningBanner`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });
    const parsedResponse: GetWarningBanner = await response.json();
    if (response.status > 299 || !response.ok) {
        console.log(response);
        throw Error(parsedResponse.error || "Unknown error");
    }
    console.log(parsedResponse);
    return parsedResponse;
}

export async function getCitationObj(citation: string): Promise<ActiveCitation> {
    const response = await fetch(`${base_url}/getcitation`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            citation: citation
        })
    });
    const parsedResponse: ActiveCitation = await response.json();
    if (response.status > 299 || !response.ok) {
        console.log(response);
        throw Error(parsedResponse.error || "Unknown error");
    }
    return parsedResponse;
}

export async function getAllUploadedFiles(folder_names: string[]): Promise<ActiveCitation> {
    const response = await fetch(`${base_url}/getfiles`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            folder_names: folder_names
        })
    });
    const parsedResponse: ActiveCitation = await response.json();
    if (response.status > 299 || !response.ok) {
        console.log(response);
        throw Error(parsedResponse.error || "Unknown error");
    }
    return parsedResponse;
}

export async function getApplicationConfig(): Promise<ApplicationTitle> {
    const response = await fetch(`${base_url}/config/details`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const parsedResponse: ApplicationTitle = await response.json();
    if (response.status > 299 || !response.ok) {
        console.log(response);
        throw Error(parsedResponse.error || "Unknown error");
    }
    console.log(parsedResponse);
    return parsedResponse;
}

export async function getRagScores() {
    const response = await fetch(`${base_url}/rag/scores`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const parsedResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        console.log(response);
        throw Error(parsedResponse.error || "Unknown error");
    }
    console.log(parsedResponse);
    return parsedResponse;
}

export async function getAllTags(): Promise<GetTagsResponse> {
    const response = await fetch(`${base_url}/getalltags`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        console.log(response);
        throw Error(parsedResponse.error || "Unknown error");
    }
    var results: GetTagsResponse = { tags: parsedResponse };
    return results;
}

export async function chatHistoryapi(id?: any, userid?: string): Promise<chatHistory> {
    const response = await fetch(`${base_url}/chatHistory?${id ? "conversationId=" + id : `userId=`}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const parsedResponse: any = await response.json();
    if (response.status > 299 || !response.ok) {
        console.log(response);
        throw Error(parsedResponse.error || "Unknown error");
    }

    var results: chatHistory = { history: parsedResponse };
    return results;
}

export async function deleteChatThread(options: ChatDeleteRequest) {
    const response = await fetch(`${base_url}/deleteChat?conversationId=${options}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json"
        }
    });

    return;
    // const parsedResponse: any = await response.json();
    // console.log(parsedResponse)
    // if (response.status > 299 || !response.ok) {
    //     console.log(response);
    //     throw Error(parsedResponse.error || "Unknown error");
    // }

    // var results: chatHistory = {history: parsedResponse};
    //     return results;
}

export async function updateChatHistoryThreadTitle(title: any, id: any) {
    const response = await fetch(`${base_url}/updateconversation?conversationId=${id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            title: title
        })
    });

    const parsedResponse: any = await response.json();
    console.log(parsedResponse);
    if (response.status > 299 || !response.ok) {
        console.log(response);
        throw Error(parsedResponse.error || "Unknown error");
    }

    var results: chatHistory = { history: parsedResponse };
    return results;
}

export async function feedback(options: any): Promise<any> {
    const response = await fetch(`${base_url}/feedback`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            feedback_type: options.feedback_type,
            user_comment: options.user_comment,
            conversation_id: options.conversation_id,
            message_id: options.message_id
        })
    });
    const parsedResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        console.log(response);
        throw Error(parsedResponse.error || "Unknown error");
    }
    return parsedResponse;
}

export async function getPromptsData(): Promise<PromptDataResponse> {
    const response = await fetch(`${base_url}/config/prompts`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json"
        }
    });

    const parsedResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw new Error((parsedResponse as any) || "Unknown error");
    }

    const data = parsedResponse.data;

    return {
        followupPrompt: data.followup_question_prompt.config_value,
        queryPromptFewShots: data.query_prompt_few_shots.config_value,
        queryPromptTemplate: data.query_prompt_template.config_value,
        responsePromptFewShots: data.response_prompt_few_shots.config_value,
        systemPrompt: data.system_prompt_prefix.config_value
    };
}

export async function updatePrompt(promptType: string, prompt: UpdatePromptRequest): Promise<void> {
    const response = await fetch(`${base_url}/config/prompts?prompt_type=${promptType}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(prompt)
    });

    if (response.status > 299 || !response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || "Unknown error");
    }
}

export async function resetPrompt(promptType: string): Promise<any> {
    const response = await fetch(`${base_url}/prompts/reset?prompt_type=${promptType}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        }
    });

    if (response.status > 299 || !response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || "Unknown error");
    }

    return await response.json();
}
