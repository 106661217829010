//  
//  

import { Button, ButtonGroup } from "react-bootstrap";
import { Label } from "@fluentui/react";

import styles from "./ResponseTempButtonGroup.module.css";

interface Props {
    className?: string;
    onClick: (_ev: any) => void;
    defaultValue?: number;
    themeColor?: string;
}

export const ResponseTempButtonGroup = ({ className, onClick, defaultValue, themeColor }: Props) => {
    return (
        <div className={`${styles.container} ${className ?? ""}`}>
            <Label>Conversation type:</Label>
            <ButtonGroup className={`${styles.buttongroup ?? ""}`} onClick={onClick}>
            <Button style={{backgroundColor: `${defaultValue == 1.0? `${themeColor}`:""}`, borderRadius: "5px 0px 0px 5px", flex:1, height: "35px"}} className={`${defaultValue == 1.0? styles.buttonleftactive : styles.buttonleft ?? ""}`} size="sm" value={1.0} bsPrefix='ia'>{"Creative"}</Button>
                <Button style={{backgroundColor: `${defaultValue == 0.6? `${themeColor}`:""}`, borderRadius: "0px", flex:1, height: "35px"}} className={`${defaultValue == 0.6? styles.buttonmiddleactive : styles.buttonmiddle ?? ""}`} size="sm" value={0.6} bsPrefix='ia'>{"Balanced"}</Button>
                <Button style={{backgroundColor: `${defaultValue == 0? `${themeColor}`:""}`, borderRadius: "0px 5px 5px 0px", flex:1, height: "35px"}} className={`${defaultValue == 0? styles.buttonrightactive : styles.buttonright ?? ""}`} size="sm" value={0} bsPrefix='ia'>{"Precise"}</Button>
            </ButtonGroup>
        </div>
    );
};