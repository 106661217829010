export const enum Approaches {
    RetrieveThenRead = "rtr",
    ReadRetrieveRead = "rrr",
    ReadDecomposeAsk = "rda"
}

export type AskRequestOverrides = {
    useSemanticRanker?: boolean;
    useEnrichedEmbeddings?: boolean;
    enableCompositeScore?: boolean;
    useMultiVectorQuery?: boolean;
    queryTransformationKey?: string;
    retriverFeature?: string;
    semanticCaptions?: boolean;
    excludeCategory?: string;
    top?: number;
    temperature?: number;
    promptTemplate?: string;
    promptTemplatePrefix?: string;
    promptTemplateSuffix?: string;
    suggestFollowupQuestions?: boolean;
    userPersona?: string;
    systemPersona?: string;
    aiPersona?: string;
    responseLength?: number;
    responseTemp?: number;
    selectedFolders?: string;
    selectedTags?: string;
    selectedFiles?: string;
    modelName?: string;
};

export type AskRequest = {
    question: string;
    approach: Approaches;
    overrides?: AskRequestOverrides;
};

export type AskResponse = {
    conversation_id: any;
    message_id: any;
    answer: string;
    thoughts: string | null;
    data_points: string[];
    scores: any;
    // citation_lookup: {}
    // added this for citation bug. aparmar.
    citation_lookup: { [key: string]: { citation: string; source_path: string; page_number: string } };
    error?: string;
    latency: any;
    usage: any;
    feedback_type: any;
    model: any;
};

export type ChatTurn = {
    user: string;
    bot?: string;
};

export type chatHistory = {
    [x: string]: any;
    history: [];
};

export type ChatRequest = {
    history: ChatTurn[];
    approach: Approaches;
    overrides?: AskRequestOverrides;
    conversationId?: string;
    chat_type?: string;
};

export type ChatResponse = {
    answer: string;
    thoughts: string | null;
    data_points: string[];
    approach: Approaches;
    thought_chain: { [key: string]: string };
    work_citation_lookup: { [key: string]: { citation: string; source_path: string; page_number: string } };
    web_citation_lookup: { [key: string]: { citation: string; source_path: string; page_number: string } };
    citation_lookup: { [key: string]: { citation: string; source_path: string; page_number: string } };
    conversation_id: any;
    message_id: any;
    latency: any;
    usage: any;
    feedback_type: any;
    model: any;
    error?: string;
    scores: any;
};
export const enum ChatMode {
    WorkOnly = 0,
    WorkPlusWeb = 1,
    Ungrounded = 2
}
export type ChatRequestV2 = {
    history: ChatTurn[];
    approach: Approaches;
    overrides?: ChatRequestOverrides;
    citation_lookup: { [key: string]: { citation: string; source_path: string; page_number: string } };
    thought_chain: { [key: string]: string };
    userId: string;
    conversationId?: string;
    chat_type: string;
    systemPrompt?: string;
};

export type ChatRequestOverrides = {
    semanticRanker?: boolean;
    semanticCaptions?: boolean;
    excludeCategory?: string;
    top?: number;
    temperature?: number;
    promptTemplate?: string;
    promptTemplatePrefix?: string;
    promptTemplateSuffix?: string;
    suggestFollowupQuestions?: boolean;
    byPassRAG?: boolean;
    userPersona?: string;
    systemPersona?: string;
    aiPersona?: string;
    responseLength?: number;
    responseTemp?: number;
    selectedFolders?: string;
    selectedTags?: string;
    modelName?: string;
    useSemanticRanker?: boolean;
    useEnrichedEmbeddings?: boolean;
    enableCompositeScore?: boolean;
    useMultiVectorQuery?: boolean;
    queryTransformationKey?: string;
    selectedFiles?: string;
    retriverFeature?: string;
    systemPrompt?: string;
};

export type ChatDeleteRequest = {
    id: string;
};

export type BlobClientUrlResponse = {
    url: string;
    error?: string;
};

export type FileUploadBasicStatus = {
    id: string;
    file_path: string;
    file_name: string;
    state: string;
    start_timestamp: string;
    state_description: string;
    state_timestamp: string;
    status_updates: StatusUpdates[];
    tags: string;
};

export type StatusUpdates = {
    status: string;
    status_timestamp: string;
    status_classification: string;
};

export type AllFilesUploadStatus = {
    statuses: FileUploadBasicStatus[];
};

export type GetUploadStatusRequest = {
    timeframe: number;
    state: FileState;
    folder: string;
    tag: string;
};

export type GetFileRequest = {
    folder_names: [];
};

export type GetFileResponse = {
    statuses: [];
};

// These keys need to match case with the defined Enum in the
// shared code (functions/shared_code/status_log.py)
export const enum FileState {
    All = "ALL",
    Processing = "PROCESSING",
    Indexing = "INDEXING",
    Skipped = "SKIPPED",
    Queued = "QUEUED",
    Complete = "COMPLETE",
    Error = "ERROR",
    THROTTLED = "THROTTLED",
    UPLOADED = "UPLOADED",
    DELETING = "DELETING",
    DELETED = "DELETED"
}

export type GetInfoResponse = {
    AZURE_OPENAI_SERVICE: string;
    AZURE_OPENAI_CHATGPT_DEPLOYMENT: string;
    AZURE_OPENAI_MODEL_NAME: string;
    AZURE_OPENAI_MODEL_VERSION: string;
    AZURE_SEARCH_SERVICE: string;
    AZURE_SEARCH_INDEX: string;
    TARGET_LANGUAGE: string;
    USE_AZURE_OPENAI_EMBEDDINGS: boolean;
    EMBEDDINGS_DEPLOYMENT: string;
    EMBEDDINGS_MODEL_NAME: string;
    EMBEDDINGS_MODEL_VERSION: string;
    error?: string;
};

export type ActiveCitation = {
    response: any;
    file_name: string[];
    file_uri: string;
    processed_datetime: string;
    title: string;
    section: string;
    pages: number[];
    token_count: number;
    content: string;
    error?: string;
};

export type GetWarningBanner = {
    WARNING_BANNER_TEXT: string;
    error?: string;
};

// These keys need to match case with the defined Enum in the
// shared code (functions/shared_code/status_log.py)
export const enum StatusLogClassification {
    Debug = "Debug",
    Info = "Info",
    Error = "Error"
}

// These keys need to match case with the defined Enum in the
// shared code (functions/shared_code/status_log.py)
export const enum StatusLogState {
    Processing = "Processing",
    Skipped = "Skipped",
    Queued = "Queued",
    Complete = "Complete",
    Error = "Error",
    Throttled = "Throttled",
    Uploaded = "Uploaded",
    All = "All"
}

export type StatusLogEntry = {
    path: string;
    status: string;
    status_classification: StatusLogClassification;
    state: StatusLogState;
};

export type StatusLogResponse = {
    status: number;
    error?: string;
};

export type ApplicationTitle = {
    APPLICATION_TITLE: string;
    response: ConfigDetails;
    error?: string;
};

export type GetTagsResponse = {
    tags: string;
    error?: string;
};

export type ConfigDetails = {
    title: string;
};

export const enum AppConstants {
    userId = "00000000-0000-0000-0000-000000000000" //DEV ID
}
export type DeleteItemRequest = {
    path: string;
};

export type ResubmitItemRequest = {
    path: string;
};

export interface PromptDataResponse {
    systemPrompt?: string;
    followupPrompt?: string;
    queryPromptFewShots?: string;
    queryPromptTemplate?: string;
    responsePromptFewShots?: string;
}

export interface UpdatePromptRequest {
    prompt: string;
}

export type PromptKey = "systemPrompt" | "followupPrompt" | "queryPromptFewShots" | "queryPromptTemplate" | "responsePromptFewShots";
