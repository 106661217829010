import React, { useState } from "react";
import { Pivot, PivotItem } from "@fluentui/react";
import { ITag } from "@fluentui/react/lib/Pickers";
import { FilePicker } from "../../components/filepicker/file-picker";
import { FileStatus } from "../../components/FileStatus/FileStatus";
import { TagPickerInline } from "../../components/TagPicker/TagPicker";
import { FolderPicker } from "../../components/FolderPicker/FolderPicker";
import { getPromptsData, updatePrompt } from "../../api";
import { PromptDataResponse, PromptKey } from "../../api/models";
import styles from "./Content.module.css";
import Prompts from "../../components/Prompts/Prompts";

export interface IButtonExampleProps {
    disabled?: boolean;
    checked?: boolean;
}

const Content = () => {
    const [selectedKey, setSelectedKey] = useState<string | undefined>(undefined);
    const [selectedTags, setSelectedTags] = useState<string[] | undefined>(undefined);
    const [promptsData, setPromptsData] = useState<PromptDataResponse>({});

    const onSelectedKeyChanged = (selectedFolder: string[]) => {
        setSelectedKey(selectedFolder[0]);
    };

    const onSelectedTagsChanged = (selectedTags: ITag[]) => {
        setSelectedTags(selectedTags.map(tag => tag.name));
    };

    const handleLinkClick = async (item?: PivotItem) => {
        if (item?.props?.headerText === "Prompts") {
            const data = await getPromptsData();
            setPromptsData(data);
        }
        setSelectedKey(undefined);
    };

    const handleUpdate = async (promptType: string, prompt: string) => {
        try {
            await updatePrompt(promptType, { prompt });
            alert("Prompt updated successfully!");
        } catch (error) {
            console.error("Error updating prompt:", error);
            alert("Failed to update prompt.");
        }
    };

    return (
        <div className={styles.contentArea}>
            <Pivot aria-label="Upload Files Section" className={styles.topPivot} onLinkClick={handleLinkClick}>
                <PivotItem headerText="Upload Files" aria-label="Upload Files Tab">
                    <div className={styles.App}>
                        <h2>Upload your files here</h2>
                        <FolderPicker allowFolderCreation={true} onSelectedKeyChange={onSelectedKeyChanged} />
                        <TagPickerInline allowNewTags={true} onSelectedTagsChange={onSelectedTagsChanged} />
                        <FilePicker folderPath={selectedKey || ""} tags={selectedTags || []} />
                    </div>
                </PivotItem>
                <PivotItem headerText="Upload Status" aria-label="Upload Status Tab">
                    <FileStatus className="" />
                </PivotItem>
                <PivotItem headerText="Prompts" aria-label="Change System Prompt">
                    <Prompts className="" promptsData={promptsData} onUpdate={handleUpdate} />
                </PivotItem>
            </Pivot>
        </div>
    );
};

export default Content;
